import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { findCartItemIndex } from '@/lib/cart';
import { CartActions, CartItem, CartState, isCartStateV0 } from '@/types/cart';
import { isNotNullish } from '@/lib/utils';
import { findListingProductVariantSize, isDigitalItem } from '@/lib/product';

const defaultState: CartState = {
  cartId: '',
  cart: [],
};

const useCartState = create<CartState & CartActions>()(
  persist(
    (set, get) => ({
      ...defaultState,
      setCartId(cartId) {
        set({ cartId });
      },
      setCart(cart) {
        set({ cart });
      },
      clearCart() {
        set({ cart: [] });
      },
      removeCartItem(product) {
        const currCart = get().cart;
        const productIndex = findCartItemIndex(currCart, product);
        if (productIndex >= 0) {
          // clone the cart for new state
          const newCart = [...currCart];
          // do the removal
          newCart.splice(productIndex, 1);
          // adopt the new cart
          set({ cart: newCart });
          // success
          return true;
        }
        return false;
      },
      setQuantity(product, quantity) {
        const currCart = get().cart;
        const productIndex = findCartItemIndex(currCart, product);
        if (productIndex >= 0) {
          // clone the cart for new state
          const newCart = [...currCart];
          // update the quantity
          newCart[productIndex].quantity = Math.max(quantity, 0);
          // adopt the new cart
          set({ cart: newCart });
          // success
          return true;
        }
        return false;
      },
      addToCart(newProduct, variationId, sizeId) {
        // validate the request
        if (
          isNotNullish(newProduct.url) &&
          isNotNullish(newProduct.primaryProductId) &&
          isNotNullish(newProduct.itemGroupId) &&
          findListingProductVariantSize(newProduct, variationId, sizeId)
        ) {
          // clone the cart for new state
          const newCart = [...get().cart];

          const variantSize = findListingProductVariantSize(newProduct, variationId, sizeId);
          const isDigital = Boolean(variantSize && isDigitalItem(variantSize.size));

          // product already in cart?
          const productIndex = findCartItemIndex(newCart, {
            listingSlug: newProduct.url,
            productId: newProduct.primaryProductId,
            variationId,
            sizeId,
            isDigital,
          });

          let cartItem: CartItem;
          if (productIndex !== -1) {
            const currentCartItem = newCart[productIndex];
            // clone the cart item, so we can modify it
            cartItem = {
              ...currentCartItem,
              quantity: currentCartItem.quantity + 1,
            };
            newCart[productIndex] = cartItem;
          } else {
            cartItem = {
              listingSlug: newProduct.url,
              productId: newProduct.primaryProductId,
              variationId,
              sizeId: sizeId || 0,
              itemGroupId: newProduct.itemGroupId,
              quantity: 1,
              isDigital,
            };
            newCart.push(cartItem);
          }
          set({ cart: newCart });
          // success
          return cartItem;
        }
        // nothing addded
        return false;
      },
    }),
    {
      name: `cart-state`,
      version: 1,
      migrate: (persistedState: unknown, version: number): CartState & CartActions => {
        let v1: CartState | undefined; // current state

        // need to promote from version 0?
        if (version === 0 && isCartStateV0(persistedState)) {
          const cart: CartItem[] = [];
          persistedState?.cart?.forEach((cartItemV0) => {
            const listingSlug = cartItemV0?.url;
            const productId = cartItemV0?.primaryProductId;
            const selectedVariationId = cartItemV0?.selectedVariationId;
            const variationId =
              typeof selectedVariationId === 'string' ? Number(selectedVariationId) : selectedVariationId;
            const sizeId = cartItemV0?.selectedSizeId;
            const itemGroupId = cartItemV0?.itemGroupId;
            const quantity =
              typeof cartItemV0?.quantity === 'string' ? Number(cartItemV0?.quantity) : cartItemV0?.quantity;
            const variantSize = findListingProductVariantSize(cartItemV0, variationId, sizeId);
            const isDigital = variantSize?.size.label === 'Digital';
            if (
              typeof listingSlug === 'string' &&
              typeof productId === 'number' &&
              !Number.isNaN(productId) &&
              typeof variationId === 'number' &&
              !Number.isNaN(variationId) &&
              typeof sizeId === 'number' &&
              !Number.isNaN(sizeId) &&
              typeof itemGroupId === 'string' &&
              typeof quantity === 'number' &&
              !Number.isNaN(quantity) &&
              quantity > 0
            ) {
              cart.push({
                listingSlug,
                productId,
                variationId,
                sizeId,
                itemGroupId,
                quantity,
                isDigital,
              });
            }
          });
          v1 = {
            cart,
            cartId: persistedState.cartId,
          };
        }

        // punt to default state
        if (!v1) {
          v1 = {
            ...defaultState,
          };
        }

        return v1 as CartState & CartActions;
      },
      skipHydration: true,
    }
  )
);

export default useCartState;
