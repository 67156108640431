'use client';

import React from 'react';
import Image, { ImageProps } from 'next/image';
import { NEXT_DISABLE_IMAGE_OPTIMIZATION } from '@/constants';

/**
 * next/image Image wrapper, to allow for global modifications (like disabling optimizations)
 *
 * @param props
 * @returns
 */
const NextImage: React.FC<ImageProps> = (props) => {
  // Global NEXT_DISABLE_IMAGE_OPTIMIZATION trumps props.unoptimized, but individual usages
  // can be unoptimized if NEXT_DISABLE_IMAGE_OPTIMIZATION is false
  const { unoptimized, ...other } = props;
  // *** below is suppressed to avoid es lint warnings about alt element not being present
  // *** we're assuming that alt is part of the props and will be unfurled accordingly
  // eslint-disable-next-line jsx-a11y/alt-text
  return <Image {...other} unoptimized={unoptimized || NEXT_DISABLE_IMAGE_OPTIMIZATION} />;
};

export default NextImage;
