'use client';

import React, { ReactNode, createContext, useCallback, useMemo } from 'react';
import { Currency } from '@/gql';
import { FulfillmentOption, UserPreferences } from '@/types/preferences';
import cookieStorage from '@/lib/cookie-storage/browser';
import { USER_PREFERENCES_COOKIE, isFulfillmentOption } from '@/lib/preferences';
import { isCurrency } from '@/lib/gql';

// cookie is written by the client and read by the middleware
// (well, it's read here too, but just as the source of truth of the state of the cookie)

export type UserPreferencesActions = {
  setCurrency: (currency: Currency) => boolean; // returns true if something changed as a result of being set
  setFulfillment: (fulfillment: FulfillmentOption) => boolean; // returns true if something changed as a result of being set
};

export const UserPreferencesContext = createContext<(UserPreferences & UserPreferencesActions) | undefined>(undefined);

export type UserPreferencesProviderProps = {
  children: ReactNode;
  fulfillment: FulfillmentOption;
  currency: Currency;
};

function writeCookie(userPreferences: UserPreferences) {
  if (!navigator.cookieEnabled) {
    return false;
  }

  let somethingChanged = false;

  // load current cookie (very manual as the type is currently so simple)
  const workingPreferences: UserPreferences = {};
  const existingCookie = cookieStorage.getItem(USER_PREFERENCES_COOKIE);

  if (existingCookie) {
    const existing = JSON.parse(existingCookie);
    if (existing && typeof existing === 'object') {
      if (isFulfillmentOption(existing.fulfillment)) {
        workingPreferences.fulfillment = existing.fulfillment;
      }
      if (isCurrency(existing.currency)) {
        workingPreferences.currency = existing.currency;
      }
    }
  }

  // validate and apply to working copy, noting if we've changed something
  if (
    isFulfillmentOption(userPreferences.fulfillment) &&
    workingPreferences.fulfillment !== userPreferences.fulfillment
  ) {
    workingPreferences.fulfillment = userPreferences.fulfillment;
    somethingChanged = true;
  }
  if (isCurrency(userPreferences.currency) && workingPreferences.currency !== userPreferences.currency) {
    workingPreferences.currency = userPreferences.currency;
    somethingChanged = true;
  }

  // persist if we've changed something
  if (somethingChanged) {
    cookieStorage.setItem(USER_PREFERENCES_COOKIE, JSON.stringify(workingPreferences));
  }

  return somethingChanged;
}

export const UserPreferencesProvider: React.FC<UserPreferencesProviderProps> = ({
  children,
  fulfillment,
  currency,
}) => {
  const setCurrency = useCallback<UserPreferencesActions['setCurrency']>((currencyVal: Currency) => {
    return writeCookie({ currency: currencyVal });
  }, []);
  const setFulfillment = useCallback<UserPreferencesActions['setFulfillment']>((fulfillmentVal: FulfillmentOption) => {
    return writeCookie({ fulfillment: fulfillmentVal });
  }, []);
  const userPreferencesMemo = useMemo<UserPreferences & UserPreferencesActions>(() => {
    return { fulfillment, currency, setCurrency, setFulfillment };
  }, [currency, fulfillment, setCurrency, setFulfillment]);
  return <UserPreferencesContext.Provider value={userPreferencesMemo}>{children}</UserPreferencesContext.Provider>;
};
