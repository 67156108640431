import React, { ReactNode, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import styles from './index.module.css';

interface OverlayProps {
  className?: string;
  show?: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Overlay: React.FC<OverlayProps> = ({ show, onClose, className, children }) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as='div' onClose={onClose} className={className} data-testid='main-overlay'>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Dialog.Overlay data-testid='overlay' className={styles.overlay} />
        </Transition.Child>
        {children}
      </Dialog>
    </Transition.Root>
  );
};

export default Overlay;
