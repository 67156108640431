import { Currency, PrimaryProductFragment, ProductFragment, SizeFragment } from '@/gql';
import { StaticAssert, TypeEquals } from '@/lib/tsutils';
import { isNotNullish } from '@/lib/utils';

// current (v1) cart state/persistance types

// The bits of the cart item that identify the product
export interface CartItemDescriptor {
  listingSlug: string;
  productId: number;
  variationId: number;
  sizeId: number;
  isDigital: boolean;
}

// The rest of the cart item data
export interface CartItem extends CartItemDescriptor {
  itemGroupId: string;
  quantity: number;
}

export interface CartState {
  cart: CartItem[];
  cartId: string;
}

export type CartMeta = {
  currency: Currency;
  subTotal: number;
  quantity: number; // was total
};

export interface CartItemWithProduct {
  cartItem: CartItem;
  product: ProductFragment; // must match cartItem.productId
}

export interface CartItemWithProductVariantSize extends CartItemWithProduct {
  variant: PrimaryProductFragment; // must match cartItem.variationId AND be a member of product.primaryProducts
  size: SizeFragment; // must match cartItem.sizeId AND be a member of variant.sizes
}

// Legacy cart item (may still be persisted in local storage)
export interface CartItemV0 extends ProductFragment {
  quantity: number | string; // original was just number, but updating quantity in the cart would convert it to a string, so allow it for migration
  price: number;
  totalPrice: number;
  color?: string;
  selectedSizeId?: number | undefined;
  selectedVariationId?: string | number | undefined;
}

export interface CartStateV0 {
  cart: CartItemV0[];
  cartId: string;
  total: number;
  subTotal: number;
}

/**
 * The actions supported by the cart state
 */
export interface CartActions {
  clearCart: () => void;
  setCart: (cart: CartItem[]) => void;
  setCartId: (cartId: string) => void;
  addToCart: (newProduct: ProductFragment, variationId: number, sizeId: number) => CartItem | false;
  setQuantity: (product: CartItemDescriptor, value: number) => boolean;
  removeCartItem: (item: CartItemDescriptor) => boolean;
}

/**
 * Type guard for CartItemV0
 * @param candidate
 * @returns
 */
export function isCartItemV0(candidate: unknown): candidate is CartItemV0 {
  // note: CartItemV0 includeds ProductFragment, but as it's all optional
  // not much to check for it in here
  const v0 = candidate as CartItemV0;

  return Boolean(
    isNotNullish(v0) &&
      typeof v0 === 'object' &&
      ['number', 'string'].includes(typeof v0.quantity) &&
      typeof v0.price === 'number' &&
      typeof v0.totalPrice === 'number' &&
      ['undefined', 'number'].includes(typeof v0.color) &&
      ['undefined', 'number'].includes(typeof v0.selectedSizeId) &&
      ['undefined', 'number', 'string'].includes(typeof v0.selectedSizeId)
  );
}

/**
 * Type guard for CartStateV0
 * @param candidate
 * @returns
 */
export function isCartStateV0(candidate: unknown): candidate is CartStateV0 {
  // experimental compile time check to catch CartItem definition changes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type AssertT = StaticAssert<
    TypeEquals<
      CartStateV0,
      {
        cart: CartItemV0[];
        cartId: string;
        total: number;
        subTotal: number;
      }
    >
  >;

  const v0 = candidate as CartStateV0;
  return Boolean(
    isNotNullish(v0) &&
      typeof v0 === 'object' &&
      typeof v0.cartId === 'string' &&
      typeof v0.total === 'number' &&
      typeof v0.subTotal === 'number' &&
      Array.isArray(v0.cart) &&
      v0.cart.find((item) => !isCartItemV0(item)) === undefined
  );
}

/**
 * Type guard for CartItem
 *
 * @param candidate
 * @returns
 */
export function isCartItem(candidate: unknown): candidate is CartItem {
  // experimental compile time check to catch guarded type definition changes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type AssertT = StaticAssert<
    TypeEquals<
      CartItem,
      {
        listingSlug: string;
        productId: number;
        variationId: number;
        sizeId: number;
        itemGroupId: string;
        quantity: number;
        isDigital: boolean;
      }
    >
  >;

  const current = candidate as CartItem;
  return Boolean(
    isNotNullish(current) &&
      typeof current === 'object' &&
      typeof current.listingSlug === 'string' &&
      typeof current.productId === 'number' &&
      typeof current.variationId === 'number' &&
      typeof current.sizeId === 'number' &&
      typeof current.itemGroupId === 'string' &&
      typeof current.quantity === 'number'
  );
}

/**
 * Type guard for CartState
 *
 * @param candidate
 * @returns
 */
export function isCartState(candidate: unknown): candidate is CartState {
  // experimental compile time check to catch guarded type definition changes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type AssertT = StaticAssert<
    TypeEquals<
      CartState,
      {
        cart: CartItem[];
        cartId: string;
      }
    >
  >;

  const current = candidate as CartState;
  return Boolean(
    isNotNullish(current) &&
      typeof current === 'object' &&
      typeof current.cartId === 'string' &&
      Array.isArray(current.cart) &&
      current.cart.find((item) => !isCartItem(item)) === undefined
  );
}
