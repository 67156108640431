import { CartItem, CartItemDescriptor } from '@/types/cart';

/**
 * Find index of a cart item that matches the product detail
 *
 * @param cart The cart to search in
 * @param target The cart item product details, can be a CartItem, but we are matching identifiers not cart item instances
 * @returns The index of the product in cart, or -1 if not found
 */
export function findCartItemIndex(
  cart: CartItem[],
  { listingSlug, productId, variationId, sizeId }: CartItemDescriptor
) {
  return cart.findIndex(
    (cartItem) =>
      cartItem.listingSlug === listingSlug &&
      cartItem.productId === productId &&
      cartItem.variationId === variationId &&
      cartItem.sizeId === sizeId
  );
}
