import React, { FC, useMemo } from 'react';

type CurrencyProps = {
  amount: number;
  currencyCode: string;
  locale?: string;
};

const Currency: FC<CurrencyProps> = ({ amount, currencyCode, locale = 'en-US' }) => {
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
      }),
    [currencyCode, locale]
  );

  return <span>{formatter.format(amount)}</span>;
};

export default Currency;
