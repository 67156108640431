'use client';

import { useEffect, useMemo, useRef, useState } from 'react';
import { getStoreListing } from '@/api';
import { Currency, ProductFragment } from '@/gql';
import { useUserPreferences } from '@/hooks//use-user-preferences';
import { findListingProductVariantSize } from '@/lib/product';
import { isProductFragment } from '@/lib/gql';
import { PartialSome } from '@/lib/tsutils';
import { isNotNullish } from '@/lib/utils';
import useCartState from '@/stores/cart-state';
import {
  CartItem,
  CartItemWithProductVariantSize,
  CartItemDescriptor,
  CartItemWithProduct,
  CartMeta,
  CartState,
} from '@/types/cart';
import { DEFAULT_FULFILLMENT_REGION } from '@/constants';
import { getFulfillmentDetail } from '@/lib/preferences';
import { FulfillmentRegion } from '@/types/preferences';
import useErrorState from '@/stores/error-state';
import { ToastErrorType } from '@/lib/toast-errors';

// common sentinel for one of the not found errors
export const NotFound: unique symbol = Symbol('not found');
type CartProductLookup = (cartItem: CartItemDescriptor) => ProductFragment | typeof NotFound;

/**
 * Make a unique key string for a store/listing/product grouping
 *
 * @param storeSlug Slug of the store
 * @param listingSlug Slug of the listing
 * @param productId  ID of the product
 * @returns A string key uniquely representing the inputs
 */
function makeCartProductKey(storeSlug: string, listingSlug: string, productId: number): string {
  return `${storeSlug}.${listingSlug}.${productId}`;
}

class CartProductMap extends Map<string, ProductFragment | typeof NotFound> {}

/**
 * Helper to build a lookup function to map cart item products to the listing/product data
 *
 * @param storeSlug The slug of the current store
 * @param cartItems The cart
 * @returns CartProductLookup function with will map cart items to a product OR undefined if not found
 *          all cartItems should have an entry accessible by the lookup
 */
async function buildCartProductLookup(
  storeSlug: string,
  cartItems: CartItem[],
  region: FulfillmentRegion,
  currency: Currency
): Promise<CartProductLookup> {
  const productMap = new CartProductMap();

  await Promise.all(
    cartItems.map(async (cartItem): Promise<void> => {
      const key = makeCartProductKey(storeSlug, cartItem.listingSlug, cartItem.productId);
      // we rely on the the underlying request cache to allow us to make the fetch
      // call any time we may want data, if we change from apollo client to something
      // that doesn't cache we will need to manage that ourselves
      const productOrError = await getStoreListing(
        storeSlug,
        cartItem.listingSlug,
        cartItem.productId,
        region,
        currency
      );
      // add the product, or 'not found' if we get an error from the server (which at this point
      // are all different flavors of not found (store, listing, or product))
      productMap.set(key, isProductFragment(productOrError) ? productOrError : NotFound);
    })
  );

  // return a lookup function into the product map (returning the map would mean
  // users would need to know how to generate the key)
  return (target: CartItemDescriptor) => {
    const key = makeCartProductKey(storeSlug, target.listingSlug, target.productId);
    const value = productMap.get(key);

    // all cart products should be in the productMap, so throw if something else is requested
    /* istanbul ignore if */
    if (value === undefined) {
      throw Error(`Unexpected request for non-mapped product: ${key}`);
    }
    return value;
  };
}

/**
 * Hook to build a lookup function to convert CartItem entries into product details
 *
 * @param storeSlug The slug of the current store
 * @returns 'loading' or a CartProductLookupData, which contains a CartProductLookup
 *          function and the cartState active at lookup function generation time
 */
type CartProductLookupData = {
  lookup: CartProductLookup;
  cartState: CartState; // cart state at time of lookup generation
};
export function useCartProductLookup(storeSlug: string): CartProductLookupData | 'loading' {
  const cartState = useCartState();
  const activeRequest = useRef<Promise<any> | undefined>();
  const [retVal, setRetval] = useState<CartProductLookupData | 'loading'>('loading');
  const { fulfillment, currency } = useUserPreferences();

  useEffect(() => {
    const { region = DEFAULT_FULFILLMENT_REGION } = getFulfillmentDetail(fulfillment);
    // update the product map when inputs change
    const promise = buildCartProductLookup(storeSlug, cartState.cart, region, currency);

    // take over the active request slot
    activeRequest.current = promise;

    // we're loading
    setRetval('loading');

    // wait for the promise to resolve, then...
    promise
      .then((resultLookup) => {
        // only dirty state if we haven't been superceeded by another request
        if (activeRequest.current === promise) {
          // the extra arrow function is needed as setState() accepts a function
          // to call with the current state
          setRetval(() => ({
            lookup: resultLookup,
            cartState,
          }));
        }
      })
      .finally(() => {
        // clean up for tidiness
        if (activeRequest.current === promise) {
          activeRequest.current = undefined;
        }
      });
  }, [cartState, currency, fulfillment, storeSlug]);

  // return lookup data if we're no longer loading, and cartState hasn't changed
  // since retVal was set
  if (retVal !== 'loading' && retVal.cartState === useCartState.getState()) {
    return retVal;
  }

  return 'loading';
}

/**
 * Hook to build a cart with associated products
 *
 * @param storeSlug The slug of the current store
 * @returns CartItemWithProduct, but the the product field converted to optional to signify not found
 */
export function useCartProducts(storeSlug: string): PartialSome<CartItemWithProduct, 'product'>[] | 'loading' {
  const productLookupData = useCartProductLookup(storeSlug);

  return useMemo(() => {
    if (productLookupData === 'loading') {
      return 'loading';
    }

    // We're in a memo right after a similar check, but for paranoia let's check to make sure
    // the cartstate associated with the lookup function is still valid before we use it
    const { lookup, cartState } = productLookupData;
    if (cartState !== useCartState.getState()) {
      return 'loading';
    }

    return cartState.cart.map((cartItem) => {
      const result = lookup(cartItem);
      return {
        cartItem,
        product: result === NotFound ? undefined : result,
      };
    });
  }, [productLookupData]);
}

/**
 * Hook to build cart data with associated product data and.  Similar to useCartProducts(), but this version
 * remove products that failed to be looked up (gql server returned not found on the store, listing, or product)
 * from the cart.
 *
 * @param storeSlug The slug of the current store
 * @returns
 */
export function useValidatedCartProducts(storeSlug: string): CartItemWithProductVariantSize[] | 'loading' {
  const cartProducts = useCartProducts(storeSlug);
  const [validatedCartProducts, setValidatedCartProducts] = useState<CartItemWithProductVariantSize[] | 'loading'>(
    'loading'
  );

  useEffect(() => {
    if (cartProducts !== 'loading') {
      const cartState = useCartState.getState();
      const filtered: CartItemWithProductVariantSize[] = [];

      cartProducts.forEach((cartProduct) => {
        const { cartItem, product } = cartProduct;
        const { variant, size } = findListingProductVariantSize(product, cartItem.variationId, cartItem.sizeId) || {};
        // There doesn't currently seem to be a mapping of variant size when the product is mapped across regions, so
        // for now we just have to delete the item.  TODO: better
        const isAvailable =
          isNotNullish(product) &&
          isNotNullish(variant) &&
          isNotNullish(size) &&
          variant?.availableSizes?.some((availSize) => availSize.id === size.id);
        if (isAvailable) {
          filtered.push({ cartItem, product, variant, size });
        } else if (cartState.removeCartItem(cartItem)) {
          const { addError } = useErrorState.getState();
          addError(ToastErrorType.CartItemRemoved);
        }
      });

      setValidatedCartProducts(filtered);
    } else {
      setValidatedCartProducts('loading');
    }
  }, [cartProducts]);

  return validatedCartProducts;
}

/**
 * Calculate cart meta data (sub total, total quantity, etc)
 *
 * @param cartItems The full qualified cart item with all the trimmings (like size)
 * @returns An object with the meta data
 */
export function useCartMeta(cartItems: CartItemWithProductVariantSize[]): CartMeta {
  // for now we assume there is only one currency for all products
  // so pick the one in the first cart product, or fail back to the
  // user currency to have something valid-ish until something is
  // added to the cart
  const { currency: userCurrency } = useUserPreferences();
  const currency = cartItems[0]?.product?.currency || userCurrency;

  return useMemo<CartMeta>(() => {
    /* eslint-disable no-param-reassign */
    return cartItems.reduce<CartMeta>(
      (accum, entry) => {
        // finally, we can add this line item to the metadata
        const {
          cartItem: { quantity },
          size,
        } = entry;

        // accumulate
        accum.quantity += quantity;
        accum.subTotal += (Number(size.price) || 0) * quantity;

        return accum;
      },
      {
        currency,
        quantity: 0,
        subTotal: 0,
      }
    );
  }, [cartItems, currency]);
}
