'use client';

import { useContext } from 'react';
import { UserPreferencesActions, UserPreferencesContext } from '@/contexts/user-preferences-context';
import { UserPreferences } from '@/types/preferences';
import { DEFAULT_CURRENCY, DEFAULT_FULFILLMENT_OPTION } from '@/constants';
import { isCurrency } from '@/lib/gql';
import { isFulfillmentOption } from '@/lib/preferences';

export const useUserPreferences = (): Required<UserPreferences & UserPreferencesActions> => {
  const context = useContext<(UserPreferences & UserPreferencesActions) | undefined>(UserPreferencesContext);
  if (!context) {
    throw new Error('Error: useUserPreferences() must be used within a UserPreferencesProvider');
  }

  return {
    currency: isCurrency(context.currency) ? context.currency : DEFAULT_CURRENCY,
    fulfillment: isFulfillmentOption(context.fulfillment) ? context.fulfillment : DEFAULT_FULFILLMENT_OPTION,
    setCurrency: context.setCurrency,
    setFulfillment: context.setFulfillment,
  };
};
