'use client';

import React, { useState, useEffect } from 'react';
import { ImageProps } from 'next/image';
import { normalizeAssetPathUrl } from '@/lib/utils';
import NextImage from '../NextImage';

interface CustomImageProps extends Omit<ImageProps, 'src'> {
  src: string;
  storeSlug?: string;
  fallbackImage?: any;
}

const CustomImage: React.FC<CustomImageProps> = ({ src, storeSlug = '', fallbackImage = null, ...props }) => {
  const [imageSrc, setImageSrc] = useState(() => normalizeAssetPathUrl(src, storeSlug));
  const [errorCount, setErrorCount] = useState(0);

  useEffect(() => {
    setImageSrc(normalizeAssetPathUrl(src, storeSlug));
    setErrorCount(0);
  }, [src, storeSlug]);

  const handleError = () => {
    if (errorCount === 0 && fallbackImage?.src) {
      setImageSrc(normalizeAssetPathUrl(src, storeSlug));
      setErrorCount((prevCount) => prevCount + 1);
    } else {
      setImageSrc('/missing_image.png');
    }
  };

  return <NextImage src={imageSrc} {...props} onError={handleError} />;
};

export default CustomImage;
