import Cookies from 'universal-cookie';
import { CookieStorage, CookieSetOptions } from '../interface';

// browser - use universal-cookie
const cookies = new Cookies();

/**
 * Browser cookie backed storage (read-write)
 */
const cookieStorage: CookieStorage = {
  getItem: (name: string): string | null => {
    const val = cookies.get(name, { doNotParse: true });
    return typeof val === 'string' ? val : null;
  },
  setItem: (name: string, value: string, cookieOptions?: CookieSetOptions): void => {
    cookies.set(name, value, cookieOptions ?? { path: '/' });
  },
  removeItem: (name: string): void => {
    cookies.remove(name);
  },
};

export default cookieStorage;
